import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  private routeCache = new Map<string, DetachedRouteHandle>();
  
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    // Don't cache the extras route
    if (this.isExtrasRoute(route)) {
      return false;
    }
    if(this.isUserPermissions(route)){
      return false;
    }
    
    // Cache all other routes
    return true;
  }
  
  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    const routeKey = this.getRouteKey(route);
    if (routeKey) {
      this.routeCache.set(routeKey, handle);
    }
  }
  
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    // Don't attempt to attach the extras route from cache
    if (this.isExtrasRoute(route)) {
      return false;
    }
    
    const routeKey = this.getRouteKey(route);
    return !!routeKey && !!this.routeCache.get(routeKey);
  }
  
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    const routeKey = this.getRouteKey(route);
    return routeKey ? this.routeCache.get(routeKey) || null : null;
  }
  
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    // Compare the route configurations to determine if they're the same route
    console.log('future', future.routeConfig);
    console.log('curr', curr.routeConfig);
    return future.routeConfig === curr.routeConfig;
  }
  
  private getRouteKey(route: ActivatedRouteSnapshot): string | null {
    // Create a unique key for the route including path, parameters, and query parameters
    const segments = route.pathFromRoot
      .filter(r => r.routeConfig)
      .map(r => r.routeConfig!.path);
    const params = route.params;
    const paramString = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');
    const queryParams = route.queryParams;
    const queryParamString = Object.keys(queryParams).map(key => `${key}=${queryParams[key]}`).join('&');
    return `${segments.join('/')}?${paramString}&${queryParamString}`;
  }
  
  // Helper method to identify the extras route
  private isExtrasRoute(route: ActivatedRouteSnapshot): boolean {
    // Check if the route path matches the disposalsites/extras pattern with an ID
    const fullPath = this.getFullPath(route);
    return fullPath.includes('disposalsites/extras/');
  }
  private isUserPermissions(route: ActivatedRouteSnapshot): boolean {
    // Check if the route path matches the disposalsites/extras pattern with an ID
    const fullPath = this.getFullPath(route);
    return fullPath.includes('user-permission/');
  }
  

  // Get the full path of the route
  private getFullPath(route: ActivatedRouteSnapshot): string {
    const pathSegments = route.pathFromRoot
      .filter(r => r.routeConfig)
      .map(r => r.routeConfig!.path || '');
    return pathSegments.join('/');
  }
}